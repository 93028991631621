import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { HomeModel } from '../models/home-model';
// import { ReviewModel } from '../models/review-model';
// import { WeddingModel } from '../models/wedding-model';
// import { FooterModel } from '../models/footer-model';
// import { ContactUsModel } from '../models/contact-us-model';
// import { AboutUsModel } from '../models/about-us-model';
// import { HistoryModel } from '../models/history-model';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getHome() {
    return this.http.get<any>(`${this.apiUrl}/home`);
  }

  getInfo() {
    return this.http.get<any>(`${this.apiUrl}/info`);
  }

  getAboutUs() {
    return this.http.get<any>(`${this.apiUrl}/certificati`);
  }

  getContactUs() {
    return this.http.get<any>(`${this.apiUrl}/contattaci`);
  }

  getFooter() {
    return this.http.get<any>(`${this.apiUrl}/footer`);
  }

  getWedding() {
    return this.http.get<any>(`${this.apiUrl}/matrimoni`);
  }

  getEvent() {
    return this.http.get<any>(`${this.apiUrl}/eventi`);
  }

  getReviews() {
    return this.http.get<any>(`${this.apiUrl}/recensioni`);
  }

  getHistory() {
    return this.http.get<any>(`${this.apiUrl}/storia`);
  }


  // getAboutUs() {
  //   console.log("this.http.get<Home>(`${this.apiUrl}", this.http.get<AboutUsModel>(`${this.apiUrl}`));
  //   return this.http.get<AboutUsModel>(`${this.apiUrl}/certificatis`);
  // }

  // getContactUs() {
  //   console.log("this.http.get<Home>(`${this.apiUrl}", this.http.get<ContactUsModel>(`${this.apiUrl}`));
  //   return this.http.get<ContactUsModel>(`${this.apiUrl}/contattacis`);
  // }

  // getFooter() {
  //   console.log("this.http.get<Home>(`${this.apiUrl}", this.http.get<FooterModel>(`${this.apiUrl}`));
  //   return this.http.get<FooterModel>(`${this.apiUrl}/footers`);
  // }

  // getWedding() {
  //   console.log("this.http.get<Home>(`${this.apiUrl}", this.http.get<WeddingModel>(`${this.apiUrl}`));
  //   return this.http.get<WeddingModel>(`${this.apiUrl}/matrimonis`);
  // }

  // getReviews() {
  //   console.log("this.http.get<Home>(`${this.apiUrl}", this.http.get<ReviewModel>(`${this.apiUrl}`));
  //   return this.http.get<ReviewModel>(`${this.apiUrl}/recensionis`);
  // }
  // getHistory() {
  //   console.log("this.http.get<Home>(`${this.apiUrl}", this.http.get<HistoryModel>(`${this.apiUrl}`));
  //   return this.http.get<HistoryModel>(`${this.apiUrl}/storias`);
  // }

}
