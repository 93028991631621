<div id="about-us" class="about-us-container">

    <h1 id="font-black">RICONOSCIMENTI</h1>
    <h3>{{this.aboutUs?.descrizione}}</h3>

    <div class="css-slider-wrapper">
        <input *ngIf="this.aboutUs?.descrizione1" type="radio" name="slider" class="slide-radio1" checked id="slider_1">
        <input *ngIf="this.aboutUs?.descrizione2" type="radio" name="slider" class="slide-radio2" id="slider_2">
        <input *ngIf="this.aboutUs?.descrizione3" type="radio" name="slider" class="slide-radio3" id="slider_3">
        <input *ngIf="this.aboutUs?.descrizione4" type="radio" name="slider" class="slide-radio4" id="slider_4">
        <input *ngIf="this.aboutUs?.descrizione5" type="radio" name="slider" class="slide-radio4" id="slider_5">
        <input *ngIf="this.aboutUs?.descrizione6" type="radio" name="slider" class="slide-radio4" id="slider_6">
        <input *ngIf="this.aboutUs?.descrizione7" type="radio" name="slider" class="slide-radio4" id="slider_7">
        <div class="next control">
            <label *ngIf="this.aboutUs?.descrizione1" for="slider_1" class="numb1"><i class="fa fa-arrow-circle-right"></i></label>
            <label *ngIf="this.aboutUs?.descrizione2" for="slider_2" class="numb2"><i class="fa fa-arrow-circle-right"></i></label>
            <label *ngIf="this.aboutUs?.descrizione3" for="slider_3" class="numb3"><i class="fa fa-arrow-circle-right"></i></label>
            <label *ngIf="this.aboutUs?.descrizione4" for="slider_4" class="numb4"><i class="fa fa-arrow-circle-right"></i></label>
            <label *ngIf="this.aboutUs?.descrizione5" for="slider_5" class="numb4"><i class="fa fa-arrow-circle-right"></i></label>
            <label *ngIf="this.aboutUs?.descrizione6" for="slider_6" class="numb4"><i class="fa fa-arrow-circle-right"></i></label>
            <label *ngIf="this.aboutUs?.descrizione7" for="slider_7" class="numb4"><i class="fa fa-arrow-circle-right"></i></label>
        </div>
        <div class="previous control">
            <label *ngIf="this.aboutUs?.descrizione1" for="slider_1" class="numb1"><i class="fa fa-arrow-circle-left"></i></label>
            <label *ngIf="this.aboutUs?.descrizione2" for="slider_2" class="numb2"><i class="fa fa-arrow-circle-left"></i></label>
            <label *ngIf="this.aboutUs?.descrizione3" for="slider_3" class="numb3"><i class="fa fa-arrow-circle-left"></i></label>
            <label *ngIf="this.aboutUs?.descrizione4" for="slider_4" class="numb4"><i class="fa fa-arrow-circle-left"></i></label>
            <label *ngIf="this.aboutUs?.descrizione5" for="slider_5" class="numb4"><i class="fa fa-arrow-circle-left"></i></label>
            <label *ngIf="this.aboutUs?.descrizione6" for="slider_6" class="numb4"><i class="fa fa-arrow-circle-left"></i></label>
            <label *ngIf="this.aboutUs?.descrizione7" for="slider_7" class="numb4"><i class="fa fa-arrow-circle-left"></i></label>
        </div>
        <div *ngIf="this.aboutUs?.descrizione1" class="slider slide1">
            <div>
                <img class="certificate-image" src="{{ this.certificati[0] }}" />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione1"></div>
                </div>
                <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone1}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone1}}</button>
                </a>
            </div>
        </div>
        <div *ngIf="this.aboutUs?.descrizione2" class="slider slide2">
            <div>
                <img class="certificate-image" src="{{ this.certificati[1] }}"  />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione2"></div>
                </div> <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone2}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone2}}</button>
                </a>
            </div>
        </div>
        <div *ngIf="this.aboutUs?.descrizione3" class="slider slide3">
            <div>
                <img class="certificate-image" src="{{ this.certificati[2] }}" />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione3"></div>
                </div> <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone3}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone3}}</button>
                </a>
            </div>
        </div>
        <div *ngIf="this.aboutUs?.descrizione4" class="slider slide4">
            <div>
                <img class="certificate-image" src="{{ this.certificati[3] }}" />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione4"></div>
                </div> <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone4}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone4}}</button>
                </a>
            </div>
        </div>
        <div *ngIf="this.aboutUs?.descrizione5" class="slider slide4">
            <div>
                <img class="certificate-image" src="{{ this.certificati[4] }}" />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione5"></div>
                </div> <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone5}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone5}}</button>
                </a>
            </div>
        </div>
        <div *ngIf="this.aboutUs?.descrizione6" class="slider slide4">
            <div>
                <img class="certificate-image" src="{{ this.certificati[5] }}" />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione6"></div>
                </div> <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone6}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone6}}</button>
                </a>
            </div>
        </div>
        <div *ngIf="this.aboutUs?.descrizione7" class="slider slide4">
            <div>
                <img class="certificate-image" src="{{ this.certificati[6] }}" />
                <div class="about-us-text">
                    <div [innerHTML]="this.aboutUs?.descrizione7"></div>
                </div> <a target="_blank" rel="noopener noreferrer"
                    href="{{this.aboutUs?.linkBottone7}}">
                    <button class="common-button-black">{{this.aboutUs?.bottone7}}</button>
                </a>
            </div>
        </div>
    </div>

    <app-weddings></app-weddings>