<div  id="events" class="events-container">
    <h1 id="font-black">EVENTI</h1>
    <h3>{{this.events?.descrizione}}</h3>

    <div lightbox-group class="container">
        <div class="grid-container" >
            <div class="grid-item" *ngFor="let image of this.eventsPhotosLimited"><img src="{{this.apiUrl}}{{image?.url}}" lightbox [closeButtonText]="'CHIUDI'" class="gallery__img">
            </div>
        </div>
    </div>
    <div class="button-container">
        <button class="common-button button-left" *ngIf="this.imagesCounter > this.defaultImagesNumber" (click)="resetImages()">NASCONDI
            FOTO</button>
        <button class="common-button button-right" *ngIf="showButtonMore()"
            (click)="chargeMoreImages()">ALTRE FOTO</button>
    </div>
</div>

<app-reviews></app-reviews>