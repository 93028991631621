import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent extends Base implements OnInit {
  aboutUs: any;
  certificati: any[] = [];

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getAboutUs().subscribe(response => {
      this.aboutUs = response.Certificati;
      this.certificati.push(this.apiUrl + this.aboutUs.certificato1.url);
      this.certificati.push(this.apiUrl + this.aboutUs.certificato2.url);
      this.certificati.push(this.apiUrl + this.aboutUs.certificato3.url);
      this.certificati.push(this.apiUrl + this.aboutUs.certificato4.url);
      this.certificati.push(this.apiUrl + this.aboutUs.certificato5.url);
      this.certificati.push(this.apiUrl + this.aboutUs.certificato6.url);
      this.certificati.push(this.apiUrl + this.aboutUs.certificato7.url);
    });
  }

}
