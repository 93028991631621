import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent extends Base implements OnInit {
  info: any = [];

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getInfo().subscribe(response => {
      this.info = response.Info;
    });
  }
}
