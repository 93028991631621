<div id="contact-us" class="contact-us-container">
  <h1 id="font-black">CONTATTACI</h1>
  <h3>{{this.contactUs?.descrizione}}</h3>

  <form ngNoForm class="contact-form" name="EmailForm" action="https://formsubmit.co/mail@villamannaroncadelli.it" method="POST">

    <input type="hidden" name="_subject" value="Nuovo messaggio dal form contatti">
    <input type="hidden" name="_template" value="box">

    <label for="text">NOME E COGNOME <b>*</b></label>
    <input type="text" id="name" name="Nome" required>

    <label for="text">EMAIL <b>*</b></label>
    <input type="text" id="email" name="Email" required>

    <label for="text">TELEFONO <b>*</b></label>
    <input type="text" id="phone" name="Telefono" required>

    <label for="text">TESTO <b>*</b></label>
    <textarea type="text" id="message" name="Messaggio" required></textarea>

    <button class="common-button-black" type="submit">INVIA IL MESSAGGIO<sup>*</sup></button>
    <em><br/><br/><sup>*</sup>Si aprirà una pagina di controllo, spunta la casella per confermare l'invio del form. Se riscontri problemi, invia pure il tuo messaggio a <a href="mailto:mail@villamannaroncadelli.it">mail@villamannaroncadelli.it</a></em>

  </form>

</div>