import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { HomeModel } from '../models/home-model';
import { DataService } from '../services/data.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends Base implements OnInit {
  // home = new HomeModel();
  home: any = [];
  videoUrl : SafeResourceUrl;

  constructor(private dataService: DataService, private sanitizer: DomSanitizer) {
    super();

    this.dataService.getHome().subscribe(response => {
      this.home = response.Home;

      if (!isNullOrUndefined(this.home))
        this.videoUrl = sanitizer.bypassSecurityTrustResourceUrl(this.home.linkVideo);
    });
  }

  ngOnInit() {

  }

}
