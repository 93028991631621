import { Component, HostListener, OnInit } from '@angular/core';
import { Base } from '../base';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent extends Base implements OnInit {
  screenHeight: number;
  screenWidth: number;
  showItems : boolean = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.querySelector('.navbar');
    if (window.pageYOffset > element.clientHeight)
      element.classList.add('navbar-scroll-color');
    else
      element.classList.remove('navbar-scroll-color');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  menuClicked() {
    this.showItems = !this.showItems;
  }

  constructor() {
    super();
    this.onResize();
  }    

  ngOnInit() {
  }

}
