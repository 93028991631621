import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends Base implements OnInit {
  events: any = [];
  eventsPhotosLimited: any;
  imagesCounter: number;
  defaultImagesNumber: number = 6;

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    if (this.detectMob())
      this.defaultImagesNumber = 3;
    else
      this.defaultImagesNumber = 6;
    this.dataService.getEvent().subscribe(response => {
      this.events = response.Eventi;
      this.resetImages();
    });
  }

  chargeMoreImages() {
    this.eventsPhotosLimited = this.events.foto.slice(0, this.imagesCounter + this.defaultImagesNumber);
    this.imagesCounter += this.defaultImagesNumber;
  }

  resetImages() {
    this.imagesCounter = this.defaultImagesNumber;
    this.eventsPhotosLimited = this.events.foto.slice(0, this.defaultImagesNumber);
  }

  showButtonMore() {
    if (!isNullOrUndefined(this.events) && !isNullOrUndefined(this.events.foto))
      return this.imagesCounter < this.events.foto.length;

    return false;
  }
}
