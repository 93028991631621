<footer>
    <div class="footer-container">
        <div class="contacts-container">
            <div class="social-container">
                <a href="https://www.instagram.com/villa_manna_roncadelli/" target=”_blank”><i id="social-image-container"
                        class="fa fa-instagram"></i></a>
                <a href="https://www.youtube.com/watch?v=g04l8-UG_fU" target=”_blank”><i id="social-image-container"
                        class="fa fa-youtube-play"></i></a>
                <a href="https://www.facebook.com/villamannaroncadellivaghi/?__xts__[0]&__xts__[1]&__xts__[2]=68.arajivdk5m8wkgkd-fhqoeb2bfzyprqfsv5ks2ni3royjq3toqk5vzpdbyh4xvj26emud-wtxf3kluwbfszxqwmxhwyhstzwu6vwt60u4scgtnstc48fgo9tfnaxzxypqqxqcbdzfjuqv7sxr89pprtu0flbq2uqa3xakczhh53qnbffllhsk-cd49ps5lra-x2dxkhigeuy-si5xmmigzq&__xts__[3]=68.ardghbaylewvipcsmp2zo1waybt4rwgxfr709wqmd7jhzbgyq7isppi6re6mrrrtvqcjd0jhv-1qnu2jnioaxv4l1bc2ddyisporvp3rgwqqv6zgsj9nnh1h4r7xxvzmlazmcrglpdo301usy10xnhq7w2epulmiceezr25oplim1_rwrzocdjnl_sxpcvkkc_v0uxsagdgfhms1yq8pbxc" target=”_blank”><i
                        id="social-image-container" class="fa fa-facebook-square"></i></a>

            </div>
            <label>Villa Manna Roncadelli Vaghi</label>
            Via Leopardi 1, 26010, Grumone di Corte de' Frati (CR)
            <br />
            {{this.footer?.numero}}
            <br />
            <label id="contacts-mail">{{this.footer?.mail}}</label>
            <a href="https://icons8.com" target=”_blank”>Icone fornite da Icons8</a>

            <div class="iubenda-container">
                <a href="https://www.iubenda.com/privacy-policy/53641575"
                    class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                    title="Privacy Policy ">Privacy Policy</a>
                <script
                    type="text/javascript">(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);</script>
            </div>

        </div>
        <a class="map-image-container"
            href="https://www.google.it/maps/place/Villa+Manna+Roncadelli/@45.2480929,10.0815704,14z/data=!4m5!3m4!1s0x478107210aca9177:0x23415137bb07b1d5!8m2!3d45.2456888!4d10.0975582">
            <img class="map-image" src="../../assets/mapsVilla.png" /></a>
    </div>
</footer>