<div id="weddings" class="weddings-container">
    <h1 id="font-black">MATRIMONI</h1>
    <h3>{{this.weddings?.descrizione}}</h3>

    <div lightbox-group class="container">
        <div class="grid-container">
            <div class="grid-item" *ngFor="let image of this.weddingsPhotosLimited"><img
                    src="{{this.apiUrl}}{{image?.url}}" lightbox [closeButtonText]="'CHIUDI'" class="gallery__img">
            </div>
        </div>
    </div>
    <div class="button-container">
        <button class="common-button button-left" *ngIf="this.imagesCounter > this.defaultImagesNumber" (click)="resetImages()">NASCONDI
            FOTO</button>
        <button class="common-button button-right" *ngIf="showButtonMore()"
            (click)="chargeMoreImages()">ALTRE FOTO</button>
    </div>

    <div class="your-dream-container"> 
        <h1 id="font-black">{{this.weddings?.videoTitolo}}</h1>
        <div class="preview-video-container">
            <iframe *ngIf="this.videoUrl" allowfullscreen id="preview-video" width="730" height="450" [src]="this.videoUrl">
            </iframe>
        </div>
    </div>

</div>

<app-events></app-events>