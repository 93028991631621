<nav *ngIf="this.screenWidth > 800" class="navbar-container" (scroll)="onWindowScroll($event);">
    <div class="navbar" >
        <a href="#info">INFORMAZIONI</a>
        <a href="#history">STORIA</a>
        <a href="#about-us">RICONOSCIMENTI</a>
        <a href="#weddings">MATRIMONI</a>
        <a href="#events">EVENTI</a>
        <a href="#reviews">DICONO DI NOI</a>
        <a href="#contact-us">CONTATTACI</a>
    </div>
</nav>

<nav *ngIf="this.screenWidth < 800" class="navbar-container-mobile navbar" style="display:flex; flex-direction:column" (scroll)="onWindowScroll($event);">
    <div class="menu-container" (click)="menuClicked()">
        <!-- [ngClass]="{'change' : true}"-->
        <div  [ngClass]="{'change' : this.showItems}" class="bar1"></div>
        <div  [ngClass]="{'change' : this.showItems}" class="bar2"></div>
        <div  [ngClass]="{'change' : this.showItems}" class="bar3"></div>
    </div>
    <div *ngIf="this.showItems" class="navbar-items" >
        <a href="#info">INFORMAZIONI</a>
        <a href="#history">STORIA</a>
        <a href="#about-us">RICONOSCIMENTI</a>
        <a href="#weddings">MATRIMONI</a>
        <a href="#events">EVENTI</a>
        <a href="#reviews">DICONO DI NOI</a>
        <a href="#contact-us">CONTATTACI</a>
    </div>
</nav>
