import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent extends Base implements OnInit {
  reviews: any = [];
  immagine1Url: string;
  immagine2Url: string;
  immagine3Url: string;
  immagine4Url: string;

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getReviews().subscribe(response => {
      this.reviews = response.Recensioni;
       this.immagine1Url = this.apiUrl + this.reviews.foto1.url;
       this.immagine2Url = this.apiUrl + this.reviews.foto2.url;
       this.immagine3Url = this.apiUrl + this.reviews.foto3.url;
       this.immagine4Url = this.apiUrl + this.reviews.foto4.url;
    });
  }
}
