<div id="info" class="info-container">
    <h1 id="font-black">INFORMAZIONI</h1>

<div class="container">
    <div class="container-row">
        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/worldwide-location.png"/>
            <h5 class="info-titolo">{{info?.titolo1}}</h5>
            <p [innerHTML]="this.info?.descrizione1"></p>
        </div>
        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/wedding-cake.png"/>
            <h5 class="info-titolo">{{info?.titolo2}}</h5>
            <p [innerHTML]="this.info?.descrizione2"></p>
        </div>

        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/price-tag-euro.png"/>
            <h5 class="info-titolo">{{info?.titolo3}}</h5>
            <p [innerHTML]="this.info?.descrizione3"></p>
        </div>
        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/surface.png"/>
            <h5 class="info-titolo">{{info?.titolo4}}</h5>
            <p [innerHTML]="this.info?.descrizione4"></p>
        </div>
        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/car--v1.png"/>
            <h5 class="info-titolo">{{info?.titolo5}}</h5>
            <p [innerHTML]="this.info?.descrizione5"></p>
        </div>

        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/warranty.png"/>
            <h5 class="info-titolo">{{info?.titolo6}}</h5>
            <p [innerHTML]="this.info?.descrizione6"></p>
        </div>
        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/conference-call--v1.png"/>
            <h5 class="info-titolo">{{info?.titolo7}}</h5>
            <p [innerHTML]="this.info?.descrizione7"></p>
        </div>
        <div class="info-card">
            <img width="60" src="https://img.icons8.com/ios/50/000000/wedding-day.png"/>
            <h5 class="info-titolo">{{info?.titolo8}}</h5>
            <p [innerHTML]="this.info?.descrizione8"></p>
        </div>
    </div>
</div>

</div>

<app-history></app-history>