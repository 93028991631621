<div id="history" class="history-container">
    <h1>STORIA</h1>

    <div class="history-content-left">
        <div class="history-text">
            <h2>{{this.history?.titolo1}}</h2>
            <br/>
            <div [innerHTML]="this.history?.descrizione1"></div>
            </div>
        <img class="history-image" src="{{this.immagine1Url}}" class="history-image"/>
    </div>
    <div class="history-content-right">
        <img class="history-image" src={{this.immagine2Url}} class="history-image"/>
        <div class="history-text">
            <h2>{{this.history?.titolo2}}</h2>
            <br/>
            <div [innerHTML]="this.history?.descrizione2"></div>
        </div>
    </div>
    <div class="history-content-left">
        <div class="history-text">
            <h2>{{this.history?.titolo3}}</h2>
            <div [innerHTML]="this.history?.descrizione3"></div>
        </div>
        <img class="history-image" src="{{this.immagine3Url}}" class="history-image"/>
    </div>
    <div class="button-container">
        <a target="_blank" rel="noopener noreferrer" href="https://my.matterport.com/show/?m=xh6YRmRtDzK&lang=it">
            <button class="common-button">ESPLORA LA VILLA</button>
        </a>
    </div>
</div>

<app-about-us></app-about-us>