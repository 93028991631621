<div id="home" class="home-container">

    <div class="home-container-content">
        <div class="logo-img-container">
            <!-- TODO: add custom imag via cms -->
            <img class="logo-img" src="../../assets/logo-villa.png" width="300" />
        </div>
        <div class="preview-video-container">
            <iframe *ngIf="this.videoUrl" allowfullscreen id="preview-video" width="730" height="548" [src]="this.videoUrl">
            </iframe>
        </div>
    </div>

    <div class="virtual-tour-container">
        <div class="virtual-tour-container-items">
            <img src="../../assets/tour3d.png" width="30%" />
            <div class="virtual-tour-text-container">
                <h1 id="virtual-tour-title">Virtual tour</h1>
                <span id="virtual-tour-description">{{this.home?.descrizione}}</span>
            </div>
            <a target="_blank" rel="noopener noreferrer" href="https://my.matterport.com/show/?m=xh6YRmRtDzK&lang=it">
                <button class="common-button">Esplora la villa</button>
            </a>
        </div>
    </div>
</div>

<app-info></app-info>