import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends Base implements OnInit {
  footer: any = [];

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getFooter().subscribe(response => {
      this.footer = response.Footer;
    });
  }
}
