import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends Base implements OnInit {
  contactUs: any = [];
  subject = "";
  message = "";
  mailText = "";
  messageField = "";
  phoneField = "";
  nameField = "";
  validateForm = false;

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getContactUs().subscribe(response => {
      this.contactUs = response.Contattaci;
    });
    // this.resetFields();
  }

  sendMail(){    
    let object = "";
    this.nameField = (<HTMLInputElement>document.getElementById("name")).value;
    this.phoneField = (<HTMLInputElement>document.getElementById("name")).value;
    this.messageField = (<HTMLInputElement>document.getElementById("name")).value;
    this.validateForm = true;

    if (this.nameField.length > 0 && this.phoneField.length > 0 && this.messageField.length > 0) {
      object = "Nome:" + " "+ this.nameField  + " - " + 
      "Telefono:" + " " + this.phoneField;

      // this.mailText = "mailto:mail@villamannaroncadelli.it?subject=" + this.subject + "&body="+this.message;
      this.mailText = "mailto:mail@villamannaroncadelli.it?subject=" 
        + object 
        + "&body=" + this.messageField;

      window.location.href = this.mailText;
    }

    this.resetFields();
  }

  resetFields() {
    this.messageField = "";
    this.phoneField = "";
    this.nameField = "";
  }

}
