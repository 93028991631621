import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-weddings',
  templateUrl: './weddings.component.html',
  styleUrls: ['./weddings.component.scss']
})
export class WeddingsComponent extends Base implements OnInit {
  weddings: any = [];
  weddingsPhotosLimited: any;
  imagesCounter: number;
  defaultImagesNumber: number = 0;
  videoUrl : SafeResourceUrl;

  constructor(private dataService: DataService, private sanitizer: DomSanitizer) {
    super();
    this.dataService.getWedding().subscribe(response => {
      if (this.detectMob())
        this.defaultImagesNumber = 3;
      else
        this.defaultImagesNumber = 6;
      this.weddings = response.Matrimoni;

      if (!isNullOrUndefined(this.weddings))
        this.videoUrl = sanitizer.bypassSecurityTrustResourceUrl(this.weddings.linkVideo);  

      this.resetImages();
    });
  }

  ngOnInit() {
  
  }

  chargeMoreImages() {
    this.weddingsPhotosLimited = this.weddings.foto.slice(0, this.imagesCounter + this.defaultImagesNumber);
    this.imagesCounter += this.defaultImagesNumber;
  }

  resetImages() {
    this.imagesCounter = this.defaultImagesNumber;
    this.weddingsPhotosLimited = this.weddings.foto.slice(0,this.defaultImagesNumber);
  }

  showButtonMore() {
    if (!isNullOrUndefined(this.weddings) && !isNullOrUndefined(this.weddings.foto))
      return this.imagesCounter < this.weddings.foto.length;

    return false;
  }
}
