<div id="reviews" class="reviews-container">
    <div class="horizontal-line"></div>
    <h1 id="font-black">DICONO DI NOI</h1>
    <h3>{{this.reviews?.descrizione}}</h3>

    <div class="arrow-image-mobile">
        <p>SCORRI&nbsp;&nbsp;</p>
        <img width="60" src="https://img.icons8.com/ios/100/000000/archers-arrow.png"/>
    </div>

    <div class="review-container-column">
        <div class="review-container-row-right">
            <div class="review-card">
                <div class="review-card-title">
                    {{this.reviews?.titolo1}}
                </div>
                <div class="review-card-sub-title" [innerHTML]="this.reviews?.descrizione1"></div>
            </div>
            <div class="review-person-container">
                <img *ngIf="this.immagine1Url" class="review-person-image-container" src="{{this.immagine1Url}}"/>
                <div class="review-person-text-container">
                    <!-- <div class="review-person-uptitle">
                        {{this.reviews?.descrizioneNome1}}
                    </div> -->
                    <div class="review-person-name">
                        {{this.reviews?.nome1}}
                    </div>
                </div>
            </div>
        </div>

        <div class="review-container-row-left">
            <div class="review-person-container">
                <img *ngIf="this.immagine2Url" class="review-person-image-container" src="{{this.immagine2Url}}"/>
                <div class="review-person-text-container">
                    <!-- <div class="review-person-uptitle">
                        {{this.reviews?.descrizioneNome2}}
                    </div> -->
                    <div class="review-person-name">
                        {{this.reviews?.nome2}}
                    </div>
                </div>
            </div>
            <div class="review-card">
                <div class="review-card-title">
                    {{this.reviews?.titolo2}}
                </div>
                <div class="review-card-sub-title" [innerHTML]="this.reviews?.descrizione2"></div>
            </div>
        </div>

        <div class="review-container-row-right">
            <div class="review-card">
                <div class="review-card-title">
                    {{this.reviews?.titolo3}}
                </div>
                <div class="review-card-sub-title" [innerHTML]="this.reviews?.descrizione3"></div>
            </div>
            <div class="review-person-container">
                <img *ngIf="this.immagine3Url" class="review-person-image-container" src="{{this.immagine3Url}}"/>
                <div class="review-person-text-container">
                    <!-- <div class="review-person-uptitle">
                        {{this.reviews?.descrizioneNome3}}
                    </div> -->
                    <div class="review-person-name">
                        {{this.reviews?.nome3}}
                    </div>
                </div>
            </div>
        </div>

        <div class="review-container-row-left">
            <div class="review-person-container">
                <img *ngIf="this.immagine4Url" class="review-person-image-container" src="{{this.immagine4Url}}"/>
                <div class="review-person-text-container">
                    <!-- <div class="review-person-uptitle">
                        {{this.reviews?.descrizioneNome2}}
                    </div> -->
                    <div class="review-person-name">
                        {{this.reviews?.nome4}}
                    </div>
                </div>
            </div>
            <div class="review-card">
                <div class="review-card-title">
                    {{this.reviews?.titolo4}}
                </div>
                <div class="review-card-sub-title" [innerHTML]="this.reviews?.descrizione4"></div>
            </div>
        </div>
    </div>
</div>

<app-contact-us></app-contact-us>